import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import { buildHeaders } from './shared';
export const fetchCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const approveCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/approve`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const pendingCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/pending`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const fetchCustomersForBorrower = async (apiToken, borrowerId, page, sortBy, sortDirection, searchQuery, filterOption) => {
    const params = {
        page,
        ...(filterOption && { filter_option: filterOption }),
        ...(searchQuery && { search_query: searchQuery }),
        ...(sortBy && { sort_by: sortBy }),
        ...(sortDirection && { sort_direction: sortDirection })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchAllCustomersForBorrower = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/all_customers`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchFundingSummary = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/fetch_funding_summary`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const approveAllCustomers = async (apiToken, borrowerId, searchQuery) => {
    const params = {
        ...(searchQuery && { search_query: searchQuery })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/approve_all?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const unapproveAllCustomers = async (apiToken, borrowerId, searchQuery) => {
    const params = {
        ...(searchQuery && { search_query: searchQuery })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/unapprove_all?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const setCreditCheck = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/set_credit_check`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const unsetCreditCheck = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/unset_credit_check`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const selectAllCustomers = async (apiToken, borrowerId, searchQuery) => {
    const params = {
        ...(searchQuery && { search_query: searchQuery })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/select_all?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const deselectAllCustomers = async (apiToken, borrowerId, searchQuery) => {
    const params = {
        ...(searchQuery && { search_query: searchQuery })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/deselect_all?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const selectCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/select`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const selectCustomerFee = async (apiToken, borrowerId, customerId, adminFee, adminFeeReference, insuranceFee, insuranceFeeReference) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/select_with_fees`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const body = {
        admin_fee: adminFee.floatValue,
        admin_fee_reference: adminFeeReference,
        insurance_fee: insuranceFee.floatValue,
        insurance_fee_reference: insuranceFeeReference
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const deselectCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/deselect`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const setNoaSent = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/set_noa_sent`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const unsetNoaSent = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/unset_noa_sent`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const discloseCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/disclose`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PATCH'
    }).then((res) => res.json());
};
export const undiscloseCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/undisclose`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PATCH'
    }).then((res) => res.json());
};
export const chargeInsuranceFees = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/charge_insurance_fees`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const unchargeInsuranceFees = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/uncharge_insurance_fees`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const insureCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/insure`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const uninsureCustomer = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/uninsure`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isRelatedPartyDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/related_party_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isNotRelatedPartyDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/not_related_party_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isGovernmentDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/government_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isNotGovernmentDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/not_government_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isMajorCorporationDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/major_corporation_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const isNotMajorCorporationDebtor = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/not_major_corporation_debtor`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const ignoreCustomerDebtorThreshold = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/ignore_debtor_threshold`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const unignoreCustomerDebtorThreshold = async (apiToken, borrowerId, customerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/unignore_debtor_threshold`;
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT'
    }).then((res) => res.json());
};
export const updateEarlypayStatus = async (apiToken, borrowerId, customerId, earlypayStatus) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/update_earlypay_status`;
    const body = {
        earlypay_status: earlypayStatus
    };
    return fetch(endpoint, {
        headers: buildHeaders(apiToken),
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
