import React from 'react';
import AdminCustomerInfoTable from '../admin-customer-info/AdminCustomerInfoTable';
const CustomerInfo = ({ customer, apiToken, isPolicyEdit, editCustomerLink, isXeroUser, isMyobUser, refreshLink, customerPhones, customerAddresses, effectiveConcentrationLimit, enableActions, borrowerId, debtorBalance, checkIcon, isManualLedger, debtorLimit, concentrationLimit, isCompanyActiveAndChargingFees }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'customer-info' },
        React.createElement("div", { className: 'header -space-between' },
            customer.name,
            isPolicyEdit && (React.createElement(React.Fragment, null,
                React.createElement("a", { href: editCustomerLink, className: 'link -edit' }, "Edit"),
                (isXeroUser || isMyobUser) && (React.createElement("a", { href: refreshLink, className: 'link' }, "Refresh"))))),
        React.createElement(AdminCustomerInfoTable, { apiToken: apiToken, borrowerId: borrowerId, isCompanyActiveAndChargingFees: isCompanyActiveAndChargingFees, customerPhones: customerPhones, customerAddresses: customerAddresses, customer: customer, effectiveConcentrationLimit: effectiveConcentrationLimit, enableActions: enableActions, checkIcon: checkIcon, debtorBalance: debtorBalance, isManualLedger: isManualLedger, debtorLimit: debtorLimit, concentrationLimit: concentrationLimit })));
};
export default CustomerInfo;
