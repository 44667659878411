import React from 'react';
const AdjustmentCard = ({ row, userId }) => {
    const customerPath = row => {
        return `/admin/borrowers/${userId}/customers/${row.customerId}`;
    };
    return (React.createElement("div", { className: 'manual-ledger-payment-card' },
        React.createElement("div", { className: 'details' },
            React.createElement("div", null, row.reason),
            React.createElement("div", { className: 'text' },
                React.createElement("a", { href: customerPath(row) }, row.customerName))),
        React.createElement("div", { className: 'details' },
            React.createElement("div", { className: 'amount' },
                row.amountFormatted,
                ' ',
                row.reinstatedAt && (React.createElement("div", { className: 'risk-badge -high' }, "Reinstated")),
                ' ',
                row.duplicatedAt && (React.createElement("div", { className: 'risk-badge -high' }, "Duplicate"))),
            React.createElement("div", { className: 'date' }, row.recordDateFormatted))));
};
export default AdjustmentCard;
