import React from 'react';
import AddGlobalPost from './AddGlobalPost';
import AddPost from './AddPost';
import CommentOnActivity from './CommentOnActivity';
import CommentOnCustomer from './CommentOnCustomer';
import CommentOnInvoice from './CommentOnInvoice';
import CommentOnDrawdown from './CommentOnDrawdown';
import AmendedInvoice from './AmendedInvoice';
import NegativeFundingAvailable from './NegativeFundingAvailable';
const NotificationItem = (props) => {
    const { notification, amendedInvoiceThreshold } = props;
    const { attributes } = notification;
    const { action } = attributes;
    switch (action) {
        case 'comment_on_invoice':
            return React.createElement(CommentOnInvoice, { ...{ notification } });
        case 'comment_on_customer':
            return React.createElement(CommentOnCustomer, { ...{ notification } });
        case 'comment_on_activity':
            return React.createElement(CommentOnActivity, { ...{ notification } });
        case 'comment_on_drawdown':
            return React.createElement(CommentOnDrawdown, { ...{ notification } });
        case 'add_post':
            return React.createElement(AddPost, { ...{ notification } });
        case 'add_global_post':
            return React.createElement(AddGlobalPost, { ...{ notification } });
        case 'amended_invoice':
            return React.createElement(AmendedInvoice, { ...{ notification, amendedInvoiceThreshold } });
        case 'negative_funding_available':
            return React.createElement(NegativeFundingAvailable, { ...{ notification } });
        default:
            return null;
    }
};
export default NotificationItem;
