import React from 'react';
const CustomerStatus = ({ status }) => {
    switch (status) {
        case 'PENDING':
            return React.createElement("span", { className: 'status-box -medium' }, "Pending");
        case 'APPROVED':
            return React.createElement("span", { className: 'status-box -safe' }, "Approved");
        case 'UNAPPROVED':
            return React.createElement("span", { className: 'status-box -medium' }, "Unapproved");
        default:
            return null;
    }
};
export default CustomerStatus;
