import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
const GstField = ({ model, gstAttribute, gstDefaultChecked = false, gstValue, amount, amountWithGst, amountAttribute, readOnly = false, hidden = false, updateAmountWithGst }) => {
    const [gstChecked, setGstChecked] = useState(gstDefaultChecked);
    const handleGstCheckboxChange = () => {
        setGstChecked(!gstChecked);
    };
    const handleAmountWithGstChange = ({ floatValue }) => {
        const newAmount = floatValue;
        if (gstChecked) {
            updateAmountWithGst(newAmount);
        }
    };
    return (React.createElement("div", { className: 'form-group number-input -oneline', "data-testid": 'gst-field' },
        !hidden && (React.createElement("label", { className: 'label -gst-checkbox' },
            "Add GST",
            React.createElement("input", { name: `${model}[${gstAttribute}]`, type: 'hidden', value: '0' }),
            React.createElement("input", { type: 'checkbox', className: 'input form-control', name: `${model}[${gstAttribute}]`, value: '1', defaultChecked: gstDefaultChecked, onChange: handleGstCheckboxChange, disabled: readOnly }))),
        gstChecked ? (React.createElement("div", { className: 'gsttotal' },
            React.createElement("label", { className: 'label' },
                "GST ($)",
                React.createElement(NumberFormat, { className: 'input form-control', value: gstValue, readOnly: true, "data-testid": `${gstAttribute}-value`, thousandSeparator: true })),
            React.createElement("label", { className: 'label' },
                "Fee Amount inc GST ($)",
                React.createElement(NumberFormat, { className: 'input form-control', name: `${model}[${amountAttribute}]`, value: amountWithGst, "data-testid": `${amountAttribute}-with-gst`, onValueChange: handleAmountWithGstChange, decimalScale: 2, thousandSeparator: true })))) : (React.createElement("input", { type: 'number', className: 'input form-control', name: `${model}[${amountAttribute}]`, value: amount, readOnly: true, "data-testid": `${amountAttribute}-with-gst`, hidden: true }))));
};
export default GstField;
