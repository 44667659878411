import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Modal from '../../components/shared/Modal';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import ErrorList from '../shared/ErrorList';
const AddProvisionForm = ({ csrfToken, formUrl, title, modelName, provisionOptions = [], customerName, groupId, errors, isError }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    const [provisionAmount, setProvisionAmount] = useState({
        floatValue: 0,
        value: '0',
        formattedValue: ''
    });
    const hasErrors = Object.keys(errors).length > 0 && isError;
    const hasDateError = errors.date !== undefined && isError;
    const [isVisible, setIsVisible] = useState(hasErrors);
    const initialProvision = provisionOptions.find((option) => option.value === 'correction');
    const [selectedProvistionType, setSelectedProvistionType] = useState(initialProvision);
    const handleProvistionTypeChange = (selectedProvistionType) => {
        setSelectedProvistionType(selectedProvistionType);
    };
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const handleProvisionChange = (values) => {
        setProvisionAmount(values);
    };
    const dateLabelClass = classNames('label -required', {
        '-error': errors.date && isError
    });
    const provisionInputClass = classNames('input form-control', {
        '-error': errors.provisionAmount && isError
    });
    return (React.createElement("div", { className: 'action-buttons button' },
        React.createElement("div", { className: 'button -default', onClick: toggleModal }, "+ Add Provision"),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("form", { className: 'form', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
                React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'add-provision-modal' },
                    React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                    React.createElement("input", { type: 'hidden', name: `${modelName}[group_id]`, value: groupId }),
                    React.createElement("div", { className: 'header' }, "Add Provision"),
                    React.createElement("div", { className: 'content' },
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label' }, "Provision ID"),
                            React.createElement("div", null, groupId)),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label' }, "Debtor"),
                            React.createElement("div", null, customerName)),
                        React.createElement("div", { className: 'form-row -col-2' },
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("label", { className: 'label' }, "Type"),
                                React.createElement(Select, { styles: {
                                        control: baseStyles => ({
                                            ...baseStyles,
                                            minHeight: '48px'
                                        })
                                    }, options: provisionOptions, value: selectedProvistionType, onChange: handleProvistionTypeChange, placeholder: 'Select provision type', name: `${modelName}[provision_type]` })),
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("label", { className: 'label' }, `${title} Amount`),
                                React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: provisionAmount.value, name: `${modelName}[provision_amount]`, onValueChange: handleProvisionChange, className: provisionInputClass, "data-testid": 'provision-amount' }))),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label' }, "Details"),
                            React.createElement("input", { className: 'input form-control', name: `${modelName}[notes]`, defaultValue: '' })),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: dateLabelClass }, "Date"),
                            React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, hasError: hasDateError, clearButtonText: 'Clear Date', name: `${modelName}[date]`, pickerBackground: 'neutral-20', placeholderText: ' ' }),
                            isError && React.createElement(ErrorList, { errors: errors, field: 'date' })),
                        React.createElement("div", { className: 'action-buttons' },
                            React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Cancel"),
                            React.createElement("button", { type: 'submit', className: 'button -primary' }, "Submit"))))))));
};
export default AddProvisionForm;
