import React from 'react';
import classNames from 'classnames';
import PaginationBar from '../shared/PaginationBar';
import SortableTableHeaders from '../table-headers/SortableTabs';
import AdminCustomersTableBody from './AdminCustomersTableBody';
import AllControls from './AllControls';
import TableSearchBar from './TableSearchBar';
import AdminCustomerFeeModal from './AdminCustomerFeeModal';
import Filters from './Filters';
import ApproveWarningModal from '../admin-customers-modal/ApproveWarningModal';
import ApproveWarningForSelectedModal from '../admin-customers-modal/ApproveWarningForSelectedModal';
import ApproveAllWarningModal from '../admin-customers-modal/ApproveAllWarningModal';
import ExportedFilesDownloadModal from '../shared/ExportFilesDownloadModal';
const AdminCustomersTable = ({ approveAllCustomersHandler, unapproveAllCustomersHandler, apiToken, borrowerCustomersPath, borrowerId, companyId, isManualLedger, isDebtorsLedgerRecalculating, generateExportedFilePath, debtorFiles, currentPage, customersArray, isLoading, pageSize, sortBy, sortDirection, totalPages, totalItems, forwardHeaderRef, headerWidths, enableActions = false, invoiceFilter, approveCustomerHandler, pendingCustomerHandler, isRequestPending, handlePageClick, sortCustomers, selectAllCustomersHandler, deselectAllCustomersHandler, selectCustomerHandler, deselectCustomerHandler, setCustomerCreditCheckHandler, unsetCustomerCreditCheckHandler, setCustomerNoaSentHandler, unsetCustomerNoaSentHandler, discloseCustomerHandler, undiscloseCustomerHandler, insureCustomerHandler, uninsureCustomerHandler, handleSearchQueryChange, isFeesModalVisible, promptCustomerFee, promptCustomer, selectCustomerFeeHandler, isRelatedPartyDebtorHandler, isNotRelatedPartyDebtorHandler, isGovernmentDebtorHandler, isNotGovernmentDebtorHandler, isMajorCorporationDebtorHandler, isNotMajorCorporationDebtorHandler, handleFilterOptionChange, isWarningModalOpen, isSelectedWarningModalOpen, closeApproveWarningModal, closeApproveSelectedWarningModal, isApproveAllModalOpen, closeApproveAllWarningModal, setIsApproveAllModalOpen, checkIcon, displaySearchInput, updateEarlypayStatusHandler, filterOptions }) => {
    const selectedCustomers = customersArray.filter(customer => customer.isSelected).length;
    const selectedToggleSwitchClass = classNames('toggle-switch', '-buttons', {
        '-checked': selectedCustomers === customersArray.length,
        '-mid': selectedCustomers !== customersArray.length && selectedCustomers !== 0
    });
    const approvedCustomers = customersArray.filter(customer => customer.earlypayStatus === 'APPROVED').length;
    const approvedToggleSwitchClass = classNames('toggle-switch', '-buttons', {
        '-checked': approvedCustomers === customersArray.length,
        '-mid': approvedCustomers !== customersArray.length && approvedCustomers !== 0
    });
    // For now, manual ledger & connected clients (Xero & MYOB) will have different header fields
    const headerFields = isManualLedger
        ? [
            { text: 'Debtor Code', field: 'source_id', defaultSort: 'asc' },
            { text: 'Debtor Name', field: 'name', defaultSort: 'asc' },
            { text: 'Select', field: 'is_selected' },
            { text: 'Approve', field: 'earlypay_status' },
            { text: 'Advance Rate', field: 'advance_rate' },
            { text: 'Insured Limit', field: 'approved_insurance_limit' },
            {
                text: 'Debtor Limit',
                field: 'debtor_limit'
            },
            {
                text: 'Concentration Limit'
            },
            {
                text: 'Selected Ledger',
                field: 'selected_ledger'
            },
            {
                text: 'Pending Invoices'
            },
            {
                text: 'Disapproved Invoices',
                field: 'disapproved_invoices'
            },
            {
                text: 'Debtor Limit Excess',
                field: 'debtor_limit_excess'
            },
            {
                text: 'Concentration Limit Excess'
            },
            {
                text: 'Ineligible Adjustments',
                field: 'ineligible_adjustments'
            },
            {
                text: 'Approved Ledger'
            },
            {
                text: 'Debtor Advance Rate Adjustment',
                field: 'advance_rate_adjustment'
            }
        ]
        : [
            { text: 'Debtor', field: 'name', defaultSort: 'asc' },
            { text: 'Total' },
            { text: 'Approved (After Concentrations)' },
            { text: 'Unapproved' },
            { text: 'Concentration Adjustments' },
            { text: 'Advance Rate', field: 'advance_rate' },
            { text: 'Credit Checked', field: 'credit_checked' },
            { text: 'NOA Sent', field: 'noa_sent' },
            { text: 'Insured', field: 'insured' },
            { text: 'Disclosed', field: 'disclosed' },
            { text: 'Select', field: 'is_selected' },
            { text: 'Approve', field: 'earlypay_status' }
        ];
    return (React.createElement("div", { className: 'custom-panel customers-table -admin', "data-testid": 'admin-customers-table' },
        React.createElement(AdminCustomerFeeModal, { ...{
                promptCustomer,
                isFeesModalVisible,
                promptCustomerFee,
                selectCustomerFeeHandler
            } }),
        React.createElement(ApproveWarningModal, { customerName: promptCustomer.name, customerSettingsLink: `/admin/borrowers/${borrowerId}/customers/${promptCustomer.id}/general_details/edit`, isWarningModalOpen: isWarningModalOpen, closeApproveWarningModal: closeApproveWarningModal }),
        React.createElement(ApproveWarningForSelectedModal, { isSelectedWarningModalOpen: isSelectedWarningModalOpen, closeApproveSelectedWarningModal: closeApproveSelectedWarningModal }),
        React.createElement(ApproveAllWarningModal, { isApproveAllModalOpen: isApproveAllModalOpen, actionHandler: approveAllCustomersHandler, closeApproveAllWarningModal: closeApproveAllWarningModal }),
        React.createElement("div", { className: 'header -space-between' },
            React.createElement(TableSearchBar, { ...{ handleSearchQueryChange, displaySearchInput }, searchPlaceholder: 'Search debtor name or debtor code' }),
            React.createElement("div", { className: 'action-buttons' },
                enableActions && (React.createElement(AllControls, { ...{
                        approvedToggleSwitchClass,
                        selectedToggleSwitchClass,
                        deselectAllCustomersHandler,
                        selectAllCustomersHandler,
                        approveAllCustomersHandler,
                        unapproveAllCustomersHandler,
                        setIsApproveAllModalOpen
                    } })),
                React.createElement(Filters, { ...{
                        handleFilterOptionChange,
                        filterOptions
                    } }),
                isManualLedger && (React.createElement(React.Fragment, null,
                    React.createElement(ExportedFilesDownloadModal, { debtorFiles: debtorFiles, generateExportedFilePath: generateExportedFilePath }),
                    React.createElement("a", { className: 'download icon-button button -text-primary -rectangular -border-brand-a-400', href: `/admin/companies/${companyId}/customers/export_xlsx` }, "Download Excel"))))),
        React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTableHeaders, { ...{
                    forwardHeaderRef,
                    headerFields,
                    headerWidths,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortCustomers }),
            React.createElement(AdminCustomersTableBody, { ...{
                    apiToken,
                    borrowerCustomersPath,
                    borrowerId,
                    isManualLedger,
                    isDebtorsLedgerRecalculating,
                    customersArray,
                    isLoading,
                    enableActions,
                    invoiceFilter,
                    approveCustomerHandler,
                    pendingCustomerHandler,
                    isRequestPending,
                    selectCustomerHandler,
                    deselectCustomerHandler,
                    setCustomerCreditCheckHandler,
                    unsetCustomerCreditCheckHandler,
                    setCustomerNoaSentHandler,
                    unsetCustomerNoaSentHandler,
                    discloseCustomerHandler,
                    undiscloseCustomerHandler,
                    insureCustomerHandler,
                    uninsureCustomerHandler,
                    promptCustomerFee,
                    isRelatedPartyDebtorHandler,
                    isNotRelatedPartyDebtorHandler,
                    isGovernmentDebtorHandler,
                    isNotGovernmentDebtorHandler,
                    isMajorCorporationDebtorHandler,
                    isNotMajorCorporationDebtorHandler,
                    checkIcon,
                    updateEarlypayStatusHandler
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: 'customers' }))));
};
export default AdminCustomersTable;
