import React, { useState } from 'react';
import { enable as defaultEnable, disable as defaultDisable } from '../../api/admin_notification_settings';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils';
import SettingControls from './SettingControls';
const AdminNotificationSettings = ({ apiToken, borrowerId, notificationSettings, enable = defaultEnable, disable = defaultDisable, showNotifyToast = defaultShowNotifyToast }) => {
    const [settingsData, setSettingsData] = useState(notificationSettings);
    const updateSettingsData = (id, updatedSettings) => {
        const updatedData = settingsData.map((setting, index) => {
            if (setting.id === id) {
                return {
                    ...setting,
                    activityComment: updatedSettings[index].activityComment,
                    customerComment: updatedSettings[index].customerComment,
                    invoiceComment: updatedSettings[index].invoiceComment,
                    drawdownComment: updatedSettings[index].drawdownComment,
                    newPost: updatedSettings[index].newPost,
                    newGlobalPost: updatedSettings[index].newGlobalPost,
                    amendedInvoice: updatedSettings[index].amendedInvoice,
                    negativeFundingAvailable: updatedSettings[index].negativeFundingAvailable,
                    disapprovedPartPaidInvoice: updatedSettings[index].disapprovedPartPaidInvoice
                };
            }
            else {
                return setting;
            }
        });
        setSettingsData(updatedData);
    };
    const errorMessage = 'Something went wrong. Please try again.';
    const enableNotificationHandler = async (id, name) => {
        try {
            const response = await enable(apiToken, borrowerId, id, name);
            updateSettingsData(id, response);
            if (response) {
                showNotifyToast({
                    text: 'Successfully enabled ' + name.replace(/_/g, ' ') + ' notification',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const disableNotificationHandler = async (id, name) => {
        try {
            const response = await disable(apiToken, borrowerId, id, name);
            updateSettingsData(id, response);
            if (response) {
                showNotifyToast({
                    text: 'Successfully disabled ' +
                        name.replace(/_/g, ' ') +
                        ' notification',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { "data-testid": 'admin-notifification-settings' },
        settingsData.map((setting, index) => (React.createElement(SettingControls, { setting: setting, index: index, enableNotificationHandler: enableNotificationHandler, disableNotificationHandler: disableNotificationHandler, key: index }))),
        settingsData.length === 0 && (React.createElement("div", { className: 'placeholder-pane', "data-testid": 'no-admin' }, "There are no assigned admins"))));
};
export default AdminNotificationSettings;
