import React from 'react';
import ProcessingBadge from '../../components/manual-ledger-funding/ProcessingBadge';
const CustomRecourseTerm = ({ defaultRecourseTerm, isCustomRecourseTermProcessing, csrfToken, formUrl, customRecourseTerm }) => {
    return (React.createElement("div", { className: 'custom-panel -setting-pane' },
        React.createElement("div", { className: 'header' },
            React.createElement("div", null, "Recourse Term"),
            isCustomRecourseTermProcessing && React.createElement(ProcessingBadge, null)),
        React.createElement("div", { className: 'body' },
            React.createElement("form", { method: 'post', action: formUrl, className: 'form' },
                React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'flex-grid -half' },
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { htmlFor: 'custom_recourse_term', className: 'label -uppercase' }, "Custom Recourse Term"),
                        React.createElement("input", { type: 'number', minLength: 1, name: 'custom_recourse_term', defaultValue: customRecourseTerm, className: 'input form-control -outline' })),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { className: 'label -uppercase' }, "Default Recourse Term"),
                        React.createElement("div", { className: 'label -large' }, defaultRecourseTerm))),
                React.createElement("div", { className: 'flex-grid' },
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("text", { className: 'text' },
                            "If you enter ",
                            React.createElement("span", { className: 'bold' }, "0"),
                            " for the custom recourse ",
                            React.createElement("span", { className: 'bold' }, "default recourse term"),
                            ' ',
                            "will be used."))),
                React.createElement("div", { className: 'form-group form-section -section' },
                    React.createElement("div", { className: 'notice -blue' },
                        React.createElement("span", { className: 'custom-icon -notice -blue-outline' }),
                        React.createElement("p", null, "The value of the default recourse term is based on the Company's Recourse Term."))),
                React.createElement("div", { className: 'action-buttons actions -flexstart' },
                    React.createElement("input", { value: 'Save', type: 'submit', className: 'button -primary', "data-disable-with": 'Saving...' }))))));
};
export default CustomRecourseTerm;
