import React, { useState } from 'react';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
const DisableRequestDrawdown = ({ name, disableRequestDrawdown }) => {
    const isDrawdownDisabled = disableRequestDrawdown ? true : false;
    const [requestDrawdown, setRequestDrawdown] = useState(isDrawdownDisabled);
    return (React.createElement("div", { "data-testid": 'disable-request-drawdown' },
        React.createElement("div", { className: 'form-group group -oneline' },
            React.createElement("label", { className: 'label' }, "Disable Request Drawdown"),
            React.createElement("input", { type: 'hidden', name: name, value: requestDrawdown.toString() }),
            React.createElement(ToggleSwitch, { isToggled: requestDrawdown, switchOn: () => setRequestDrawdown(true), switchOff: () => setRequestDrawdown(false), testId: 'toggle-request-drawdown' }))));
};
export default DisableRequestDrawdown;
