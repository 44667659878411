import { define } from 'remount';
// Containers
import ActivityWrapper from './containers/activities/ActivityWrapper';
import AdminActivities from './containers/AdminActivities';
import AdminBorrowerActivities from './containers/AdminBorrowerActivities';
import AdminBrokerActivities from './containers/AdminBrokerActivities';
import BrokerActivities from './containers/BrokerActivities';
import BrokerClientActivities from './containers/BrokerClientActivities';
import InvoiceFinanceSignUp from './containers/invoice-finance-sign-up/InvoiceFinanceSignUp';
import AdminClientsDashboardConnector from './containers/admin-clients-dashboard/AdminClientsDashboardConnector';
import AdminCustomersDashboardConnector from './containers/admin-customers-dashboard/AdminCustomersDashboardConnector';
import AdminCreditorsDashboardConnector from './containers/admin-creditors-dashboard/AdminCreditorsDashboardConnector';
import AdminPostPanel from './containers/admin-post-panel/AdminPostPanel';
import AdminSearchPanel from './containers/admin-search-panel/AdminSearchPanel';
import BorrowerCustomersDashboardConnector from './containers/borrower-customers-dashboard/BorrowerCustomersDashboardConnector';
import BorrowerSearchPanel from './containers/borrower-search-panel/BorrowerSearchPanel';
import BorrowerDashboard from './containers/BorrowerDashboard';
import CompanyProfileDashboardConnector from './containers/company-profile-dashboard/CompanyProfileDashboardConnector';
import AssignedAdminsPanel from './containers/assigned-admins-panel/AssignedAdminsPanel';
import BrokerAssignedAdminsPanel from './containers/assigned-admins-panel/BrokerAssignedAdminsPanel';
import GeneralClientSetupContainer from './containers/client-setup/GeneralClientSetupContainer';
import RepaymentsTable from './containers/repayments-table/RepaymentsTable';
import TradeLoanApplicationDashboard from './containers/trade-loan/TradeLoanApplicationDashboard';
import SuppliersEditDashboard from './containers/trade-loan/SuppliersEditDashboard';
import TradeLoanEditDashboard from './containers/trade-loan/TradeLoanEditDashboard';
import InvoicesEditDashboard from './containers/trade-loan/InvoicesEditDashboard';
import DocumentsEditDashboard from './containers/trade-loan/DocumentsEditDashboard';
import CommissionDashboard from './containers/commission-dashboard/CommissionDashboard';
import TradeLoanCommissionDashboard from './containers/trade-loan-commission-dashboard/TradeLoanCommissionDashboard';
import TradeLoanRepaymentsTable from './containers/trade-loan-repayments-table/TradeLoanRepaymentsTable';
import EditSingleSupplierDashboard from './containers/trade-loan/EditSingleSupplierDashboard';
import AdminCustomerStatements from './containers/admin-customer-statements/AdminCustomerStatements';
import ApprovalStatusToggleContainer from './containers/ApprovalStatusToggleContainer';
import BorrowerReports from './containers/BorrowerReports';
import AddressesContainer from './containers/addresses/AddressesContainer';
import NumberField from './components/shared/NumberField';
import DrawdownApprovalOption from './containers/admin-drawdown/DrawdownApprovalOption';
import HighRiskToggle from './containers/high-risk-toggle/HighRiskToggle';
import AnzApplicationGuide from './containers/myob-bank-feed-reference/AnzApplicationGuide';
import MyobBankFeedPromptForm from './containers/myob-bank-feed-reference/MyobBankFeedPromptForm';
import InsuranceToggleForm from './containers/insurance-toggle-form/InsuranceToggleForm';
import InvoicesTableContainer from './containers/invoices-table-container/InvoicesTableContainer';
import ExpandedCustomerInvoicesTableContainer from './containers/invoices-table-container/ExpandedCustomerInvoicesTableContainer';
import DrawdownApprovedDashboard from './containers/drawdown-dashboard/DrawdownApprovedDashboard';
import InvoiceBatchesContainer from './containers/invoice-batches/InvoiceBatchesContainer';
import InvoiceBatchToggle from './containers/invoice-batch-toggle/InvoiceBatchToggle';
import InvoiceGroupList from './containers/invoice-batches/InvoiceGroupList';
import DebtorBonafidePanel from './containers/debtor-bonafide-panel/DebtorBonafidePanel';
import AdminDebtorsTable from './containers/admin-debtors-table/AdminDebtorsTable';
// Components
import AccordionContainer from './components/accordion-pane/AccordionContainer';
import AccordionTable from './components/payments-table/AccordionTable';
import HomeAccordionContainer from './components/accordion-pane/HomeAccordionContainer';
import AttachmentGallery from './components/attachment-gallery/AttachmentGallery';
import PostBody from './components/activity-feed/PostBody';
import CustomPassword from './components/shared/CustomPassword';
import CustomersTable from './components/customers-table/CustomersTable';
import DropdownSelect from './components/shared/DropdownSelect';
import NewUserContainer from './components/new-user-container/NewUserContainer';
import EditProfileContainer from './components/edit-profile-container/EditProfileContainer';
import FileUpload from './components/file-upload/FileUpload';
import FilterTabs from './components/filter-tabs/FilterTabs';
import FundingAttributesEditor from './components/funding-attributes-editor/FundingAttributesEditor';
import DaysPastIssue from './components/invoice-status/DaysPastIssue';
import InvoiceStatus from './components/invoice-status/InvoiceStatus';
import VerificationToggle from './components/shared/VerificationToggle';
import LandingNavbar from './components/landing-navbar/LandingNavbar';
import LoansOutstandingChart from './components/loans-outstanding-chart/LoansOutstandingChart';
import CommentPanel from './components/comment-panel/CommentPanel';
import NotificationToast from './components/notification-toast/NotificationToast';
import Notifications from './components/notifications/Notifications';
import AdminRequestDrawdown from './components/request-drawdown/AdminRequestDrawdown';
import BorrowerRequestDrawdown from './components/request-drawdown/BorrowerRequestDrawdown';
import EarlypayLogo from './components/shared/EarlypayLogo';
import SlideshowContainer from './components/slideshow-container/SlideshowContainer';
import TabNavigation from './components/tab-navigation/TabNavigation';
import ToggleMenu from './components/toggle-menu/ToggleMenu';
import TransactionHistory from './components/transaction-history/TransactionHistory';
import VerticalNavigation from './components/vertical-navigation/VerticalNavigation';
import UploadRepaymentsCsv from './components/upload-repayments-csv/UploadRepaymentsCsv';
import ReminderPanel from './components/reminder-panel/ReminderPanel';
import CopyUrl from './components/copy-url/CopyUrl';
import RtgsToggle from './components/drawdowns-table/RtgsToggle';
import ProcessedAtBankToggle from './components/drawdowns-table/ProcessedAtBankToggle';
import FilterDropdown from './components/drawdowns-table/filter-dropdown/FilterDropdown';
import PasswordValidation from './components/edit-profile-container/PasswordValidation';
import RiskLogForm from './components/risk-log-form/RiskLogForm';
import ProvisionForm from './components/provision/ProvisionForm';
import AddProvisionForm from './components/provision/AddProvisionForm';
import AddWriteOffForm from './components/provision/AddWriteOffForm';
import WriteOffForm from './components/provision/WriteOffForm';
import RiskActivityDetails from './components/risk-activity-details/RiskActivityDetails';
import UnassignedTransactionsTable from './components/repayments-table/UnassignedTransactionsTable';
import ReleaseNoteEditor from './components/release-note-editor/ReleaseNoteEditor';
import ReleaseNoteFilter from './components/release-note-editor/ReleaseNoteFilter';
import OneOffFeesEditor from './components/one-off-fees-editor/OneOffFeesEditor';
import AdminCustomerInfoTable from './components/admin-customer-info/AdminCustomerInfoTable';
import DebtorConcentrationsForm from './components/debtor-concentrations/DebtorConcentrationsForm';
import AuditLogFilters from './components/audit-logs/AuditLogFilters';
import FilterRepayment from './components/repayments-table/filter-repayment/FilterRepayment';
import DownloadRepayment from './components/repayments-table/download-repayment/DownloadRepayment';
import FinanceEditor from './components/finance-editor/FinanceEditor';
import AttachmentsPanel from './components/release-notes/AttachmentsPanel';
import EarlypayRegionOwnerForm from './components/client-setup/EarlypayRegionOwnerForm';
import ProductDetailsForm from './components/client-setup/ProductDetailsForm';
import CommissionRatesForm from './components/commission-rates-form/CommissionRatesForm';
import MisbankedFilters from './components/misbanked-filters/MisbankedFilters';
import SupplierDetails from './components/trade-loan/SupplierDetails';
import PaymentDetails from './components/trade-loan/PaymentDetails';
import TradeLoanSteps from './components/trade-loan/TradeLoanSteps';
import LoanApplication from './components/trade-loan/LoanApplication';
import TradeLoanForm from './components/trade-loan/TradeLoanForm';
import CompanyStatus from './components/company-status/CompanyStatus';
import InvoiceTodoRule from './components/invoice-todo-rules/InvoiceTodoRule';
import ApprovalOption from './components/trade-loan/ApprovalOptions';
import TradeFinanceFeeEditor from './components/trade-finance-fee/TradeFinanceFeeEditor';
import TextListTooltip from './components/custom-tooltip/TextListTooltip';
import InvoiceDatesForm from './components/invoice-dates-form/InvoiceDatesForm';
import FilterDashboard from './components/toggle-admin-filter/FilterDashboard';
import DateRangePicker from './components/shared/DateRangePicker';
import TradeLoanSupplierApproval from './components/trade-loan-supplier/TradeLoanSupplierApproval';
import TradeLoanUnassignedTransactionsTable from './components/trade-loan-repayments-table/TradeLoanUnassignedTransactionsTable';
import UploadTradeLoanRepaymentsCsv from './components/upload-trade-loan-repayments-csv/UploadTradeLoanRepaymentsCsv';
import DownloadTradeLoanRepayment from './components/trade-loan-repayments-table/download-repayment/DownloadTradeLoanRepayment';
import FilterTradeLoanRepayment from './components/trade-loan-repayments-table/filter-repayment/FilterTradeLoanRepayment';
import TradeLoanRepaymentsDatePicker from './components/trade-loan-repayments-table/DatePicker';
import FilterAgedReceivable from './components/aged-receivable/FilterAgedReceivable';
import InvoicePreviewModal from './components/invoice-preview-modal/InvoicePreviewModal';
import InvoicesDisapproval from './components/invoices-disapproval/InvoicesDisapproval';
import InvoicesTable from './components/invoices-table/InvoicesTable';
import ReportDownload from './components/report-download/ReportDownload';
import AdminNotificationSettings from './components/admin-notification-settings/AdminNotificationSettings';
import PenaltyControls from './components/trade-loan/PenaltyControls';
import TextTooltip from './components/custom-tooltip/TextTooltip';
import DownloadInvoiceButton from './components/invoice-download/DownloadInvoiceButton';
import CumulativeInterestTooltip from './components/trade-loan-schedules/CumulativeInterestTooltip';
import PaymentTooltip from './components/trade-loan-schedules/PaymentTooltip';
import ReserveAmountForm from './components/client-setup/ReserveAmountForm';
import TradeLoanFilterTabs from './components/trade-loan/TradeLoanFilterTabs';
import DisapprovalReasonTooltip from './components/invoices-table/DisapprovalReasonTooltip';
import BreakdownInterest from './containers/admin-tooltip/BreakdownInterest';
import FindAndAssignInvoicesModal from './components/manual-ledger/FindAndAssignInvoicesModal';
import InvoicePartialPaymentForm from './components/invoice-partial-payment-form/InvoicePartialPaymentForm';
import SuspenseModal from './containers/suspense-modal/SuspenseModal';
import XeroPaymentsTable from './components/payments-table/XeroPaymentsTable';
import MyobPaymentsTable from './components/payments-table/MyobPaymentsTable';
import CashReceivedDrawer from './components/manual-ledger/cash-received/CashReceivedDrawer';
import UploadedFileNotesDrawer from './components/manual-ledger/UploadedFileNotesDrawer';
import AvailabilityAdjustmentDrawer from './components/manual-ledger/AvailabilityAdjustmentDrawer';
import CashAdjustmentsDrawer from './components/manual-ledger/CashAdjustmentsDrawer';
import LedgerAdjustmentsDrawer from './components/manual-ledger/LedgerAdjustmentsDrawer';
import ConfirmReconciliationDrawer from './components/manual-ledger/ConfirmReconciliationDrawer';
import InvoicesDrawer from './components/manual-ledger/InvoicesDrawer';
import CreditNotesDrawer from './components/manual-ledger/CreditNotesDrawer';
import ReconcilingAsOf from './components/manual-ledger/ReconcilingAsOf';
import CloseButton from './components/trade-loan/CloseButton';
import CloseOutModal from './components/use-reserve-amount/CloseOutModal';
import TradeLoanInterestTooltip from './components/trade-finance-fee/TradeLoanInterestTooltip';
import UndoOneOffFee from './components/one-off-fees-editor/UndoOneOffFee';
import TwoFactorWizard from './containers/two-factor-wizard/TwoFactorWizard';
import RecoveryCode from './components/two-factor-wizard/RecoveryCode';
import InvoicePdfsModal from './components/invoice-pdfs-modal/InvoicePdfsModal';
import DrawdownType from './components/drawdown-type/DrawdownType';
import UseReserveAmount from './components/use-reserve-amount/UseReserveAmount';
import InvoiceFinanceTypeSelector from './components/company-settings/InvoiceFinanceTypeSelector';
import EndorseButton from './components/provision/EndorseButton';
import DrawdownsDownload from './components/drawdowns-download/DrawdownsDownload';
import DownloadABA from './components/drawdowns-table/DownloadABA';
import ProgressBar from './components/progress-bar/ProgressBar';
import PopupFileUpload from './components/popup-file-upload/PopupFileUpload';
import TradeLoanSettings from './components/trade-loan-settings/TradeLoanSettings';
import ButtonForModal from './components/modal/ButtonForModal';
import AdminDebtorGrossLedgerAnalysisConnector from './containers/admin-debtor-gross-ledger-analysis/AdminDebtorGrossLedgerAnalysisConnector';
import ExportedFilesDownloadModal from './components/shared/ExportFilesDownloadModal';
import ManualLedgerFunding from './components/manual-ledger-funding/ManualLedgerFunding';
import AvailabilityAdjustmentModal from './components/availability-adjustment/AvailabilityAdjustmentModal';
import DisapprovalReportFilter from './components/disapproval-report-dropdown/DisapprovalReportFilter';
import InvoiceDisapprovalModal from './containers/invoice-disapproval-modal/InvoiceDisapprovalModal';
import DebtorHistoriesDatePicker from './components/debtors-transaction-histories/DebtorHistoriesDatePicker';
import BorrowerDebtorGrossLedgerAnalysis from './containers/borrower-debtor-gross-ledger-analysis/BorrowerDebtorGrossLedgerAnalysis';
import CustomRecourseTerm from './components/custom-recourse-term/CustomRecourseTerm';
import DisableRequestDrawdown from './containers/disable-request-drawdown/DisableRequestDrawdownToggle';
// define React components here
define({
    'x-disapproval-accordion-table': AccordionTable,
    'x-disapproval-reason-tool-tip': DisapprovalReasonTooltip,
    'x-trade-loan-filter-tabs': TradeLoanFilterTabs,
    'x-filter-aged-receivable': FilterAgedReceivable,
    'x-invoices-disapproval': InvoicesDisapproval,
    'x-filter-repayments': FilterRepayment,
    'x-download-repayments': DownloadRepayment,
    'x-activity-wrapper': ActivityWrapper,
    'x-admin-activities': AdminActivities,
    'x-admin-borrower-activities': AdminBorrowerActivities,
    'x-admin-broker-activities': AdminBrokerActivities,
    'x-broker-activities': BrokerActivities,
    'x-broker-assigned-admins-panel': BrokerAssignedAdminsPanel,
    'x-broker-client-activities': BrokerClientActivities,
    'x-admin-customers-dashboard': AdminCustomersDashboardConnector,
    'x-admin-creditors-dashboard': AdminCreditorsDashboardConnector,
    'x-admin-post-panel': AdminPostPanel,
    'x-admin-search-panel': AdminSearchPanel,
    'x-attachment-gallery': AttachmentGallery,
    'x-borrower-customers-dashboard': BorrowerCustomersDashboardConnector,
    'x-borrower-dashboard': BorrowerDashboard,
    'x-borrower-search-panel': BorrowerSearchPanel,
    'x-borrower-reports': BorrowerReports,
    'x-custom-password': CustomPassword,
    'x-company-profile-dashboard': CompanyProfileDashboardConnector,
    'x-days-past-issue': DaysPastIssue,
    'x-dropdown-select': DropdownSelect,
    'x-file-upload': FileUpload,
    'x-filter-tabs': FilterTabs,
    'x-funding-attributes-editor': FundingAttributesEditor,
    'x-comment-panel': CommentPanel,
    'x-notification-toast': NotificationToast,
    'x-slideshow-container': SlideshowContainer,
    'x-accordion-container': AccordionContainer,
    'x-home-accordion-container': HomeAccordionContainer,
    'x-invoice-status': InvoiceStatus,
    'x-landing-navbar': LandingNavbar,
    'x-loans-outstanding-chart': LoansOutstandingChart,
    'x-edit-profile-container': EditProfileContainer,
    'x-new-user-container': NewUserContainer,
    'x-borrower-request-drawdown': BorrowerRequestDrawdown,
    'x-admin-request-drawdown': AdminRequestDrawdown,
    'x-transaction-history': TransactionHistory,
    'x-customers-table': CustomersTable,
    'x-tab-navigation': TabNavigation,
    'x-toggle-menu': ToggleMenu,
    'x-vertical-navigation': VerticalNavigation,
    'x-earlypay-logo': EarlypayLogo,
    'x-notifications': Notifications,
    'x-verification-toggle': VerificationToggle,
    'x-post-body': PostBody,
    'x-upload-repayments-csv': UploadRepaymentsCsv,
    'x-assigned-admins-panel': AssignedAdminsPanel,
    'x-invoice-finance-sign-up': InvoiceFinanceSignUp,
    'x-reminder-panel': ReminderPanel,
    'x-copy-url': CopyUrl,
    'x-rtgs-toggle': RtgsToggle,
    'x-processed-at-bank-toggle': ProcessedAtBankToggle,
    'x-admin-client-dashboard': AdminClientsDashboardConnector,
    'x-processed-drawdowns-filter': FilterDropdown,
    'x-password-validation': PasswordValidation,
    'x-risk-log-form': RiskLogForm,
    'x-provision-form': ProvisionForm,
    'x-add-provision-form': AddProvisionForm,
    'x-add-write-off-form': AddWriteOffForm,
    'x-write-off-form': WriteOffForm,
    'x-endorse-button': EndorseButton,
    'x-risk-activity-details': RiskActivityDetails,
    'x-unassigned-transactions-table': UnassignedTransactionsTable,
    'x-release-note-editor': ReleaseNoteEditor,
    'x-release-note-filter': ReleaseNoteFilter,
    'x-one-off-fees-editor': OneOffFeesEditor,
    'x-admin-customer-info-table': AdminCustomerInfoTable,
    'x-debtor-concentrations-form': DebtorConcentrationsForm,
    'x-audit-log-filters': AuditLogFilters,
    'x-finance-editor': FinanceEditor,
    'x-release-note-attachments-panel': AttachmentsPanel,
    'x-earlypay-region-owner-form': EarlypayRegionOwnerForm,
    'x-product-details-form': ProductDetailsForm,
    'x-general-client-setup': GeneralClientSetupContainer,
    'x-commission-rates-form': CommissionRatesForm,
    'x-misbanked-filters': MisbankedFilters,
    'x-supplier-details': SupplierDetails,
    'x-payment-details': PaymentDetails,
    'x-trade-loan-steps': TradeLoanSteps,
    'x-loan-application': LoanApplication,
    'x-trade-loan-form': TradeLoanForm,
    'x-company-status': CompanyStatus,
    'x-drawdown-type': DrawdownType,
    'x-repayments-table': RepaymentsTable,
    'x-todo-rule': InvoiceTodoRule,
    'x-invoice-todo-rule': InvoiceTodoRule,
    'x-approval-option': ApprovalOption,
    'x-trade-loan-application-dashboard': TradeLoanApplicationDashboard,
    'x-trade-finance-fee-editor': TradeFinanceFeeEditor,
    'x-invoice-dates-form': InvoiceDatesForm,
    'x-suppliers-edit-dashboard': SuppliersEditDashboard,
    'x-trade-loan-edit-dashboard': TradeLoanEditDashboard,
    'x-invoices-edit-dashboard': InvoicesEditDashboard,
    'x-documents-edit-dashboard': DocumentsEditDashboard,
    'x-text-list-tooltip': TextListTooltip,
    'x-date-picker': DateRangePicker,
    'x-filter-dashboard': FilterDashboard,
    'x-trade-loan-supplier-approval': TradeLoanSupplierApproval,
    'x-commission-dashboard': CommissionDashboard,
    'x-trade-loan-commission-dashboard': TradeLoanCommissionDashboard,
    'x-trade-loan-unassigned-transactions-table': TradeLoanUnassignedTransactionsTable,
    'x-upload-trade-loan-repayments-csv': UploadTradeLoanRepaymentsCsv,
    'x-trade-loan-repayments-table': TradeLoanRepaymentsTable,
    'x-trade-loan-download-repayments': DownloadTradeLoanRepayment,
    'x-trade-loan-filter-repayments': FilterTradeLoanRepayment,
    'x-trade-loan-repayments-date-picker': TradeLoanRepaymentsDatePicker,
    'x-invoice-preview-modal': InvoicePreviewModal,
    'x-trade-loan-edit-single-supplier-dashboard': EditSingleSupplierDashboard,
    'x-admin-customer-statements': AdminCustomerStatements,
    'x-invoices-table': InvoicesTable,
    'x-report-download': ReportDownload,
    'x-download-invoice-button': DownloadInvoiceButton,
    'x-approval-status-toggle-container': ApprovalStatusToggleContainer,
    'x-admin-notification-settings': AdminNotificationSettings,
    'x-trade-loan-penalty-controls': PenaltyControls,
    'x-find-and-assign-invoices-modal': FindAndAssignInvoicesModal,
    'x-text-tooltip': TextTooltip,
    'x-cumulative-interest-tooltip': CumulativeInterestTooltip,
    'x-payment-tooltip': PaymentTooltip,
    'x-availability-adjustment': AvailabilityAdjustmentModal,
    'x-reserve-amount-form': ReserveAmountForm,
    'x-addresses-container': AddressesContainer,
    'x-breakdown-interest': BreakdownInterest,
    'x-number-field': NumberField,
    'x-drawdown-approval-option': DrawdownApprovalOption,
    'x-high-risk-toggle': HighRiskToggle,
    'x-anz-application-guide': AnzApplicationGuide,
    'x-myob-bank-feed-prompt-form': MyobBankFeedPromptForm,
    'x-invoice-partial-payment-form': InvoicePartialPaymentForm,
    'x-insurance-toggle-form': InsuranceToggleForm,
    'x-suspense-modal': SuspenseModal,
    'x-cash-received-drawer': CashReceivedDrawer,
    'x-uploaded-file-notes-drawer': UploadedFileNotesDrawer,
    'x-availability-adjustment-drawer': AvailabilityAdjustmentDrawer,
    'x-ledger-adjustments-drawer': LedgerAdjustmentsDrawer,
    'x-cash-adjustments-drawer': CashAdjustmentsDrawer,
    'x-confirm-reconciliation-drawer': ConfirmReconciliationDrawer,
    'x-invoices-drawer': InvoicesDrawer,
    'x-credit-notes-drawer': CreditNotesDrawer,
    'x-reconciling-as-of': ReconcilingAsOf,
    'x-xero-payments-table': XeroPaymentsTable,
    'x-myob-payments-table': MyobPaymentsTable,
    'x-close-trade-loan-btn': CloseButton,
    'x-close-out-modal': CloseOutModal,
    'x-trade-loan-interest-tooltip': TradeLoanInterestTooltip,
    'x-undo-one-off-fee': UndoOneOffFee,
    'x-two-factor-wizard': TwoFactorWizard,
    'x-two-factor-recovery-code': RecoveryCode,
    'x-invoices-table-container': InvoicesTableContainer,
    'x-expanded-customer-invoices-table-container': ExpandedCustomerInvoicesTableContainer,
    'x-invoice-pdfs-modal': InvoicePdfsModal,
    'x-drawdown-approved-dashboard': DrawdownApprovedDashboard,
    'x-drawdown-download-button': DrawdownsDownload,
    'x-use-reserve-amount': UseReserveAmount,
    'x-invoice-batches-container': InvoiceBatchesContainer,
    'x-invoice-batch-toggle': InvoiceBatchToggle,
    'x-invoice-group-list': InvoiceGroupList,
    'x-invoice-finance-type-selector': InvoiceFinanceTypeSelector,
    'x-progress-bar': ProgressBar,
    'x-popup-file-upload': PopupFileUpload,
    'x-download-aba': DownloadABA,
    'x-bonafide-panel': DebtorBonafidePanel,
    'x-trade-loan-settings': TradeLoanSettings,
    'x-admin-debtors-table': AdminDebtorsTable,
    'x-button-for-modal': ButtonForModal,
    'x-admin-debtor-gross-ledger-analysis': AdminDebtorGrossLedgerAnalysisConnector,
    'x-exported-files-download': ExportedFilesDownloadModal,
    'x-disable-request-drawdown-toggle': DisableRequestDrawdown,
    'x-manual-ledger-funding': ManualLedgerFunding,
    'x-disapproval-report-filter': DisapprovalReportFilter,
    'x-disapproval-modal': InvoiceDisapprovalModal,
    'x-debtor-histories-date-picker': DebtorHistoriesDatePicker,
    'x-borrower-debtor-gross-ledger-analysis': BorrowerDebtorGrossLedgerAnalysis,
    'x-custom-recourse-term': CustomRecourseTerm
});
