import React, { useEffect, useRef, useState } from 'react';
import { showNotifyToast } from '../../utils/notifyToast';
import AdminCustomersTable from '../../components/admin-customers-table/AdminCustomersTable';
import FundingSummaryPanel from '../../components/funding-summary/FundingSummaryPanel';
import debounce from 'lodash.debounce';
import { isValidAbn } from '../../validations/abn';
import { updateEarlypayStatus } from '../../api/admin_customer';
const AdminCustomersDashboard = ({ apiToken, approveAllCustomers, borrowerCustomersPath, borrowerId, companyId, isManualLedger, generateExportedFilePath, debtorFiles, enableActions = false, invoiceFilter, isCompanyActiveAndChargingFees, approveCustomer, fetchFundingSummary, fetchCustomersForBorrower, pendingCustomer, setCreditCheck, unsetCreditCheck, unapproveAllCustomers, selectAllCustomers, deselectAllCustomers, selectCustomer, selectCustomerFee, deselectCustomer, setNoaSent, unsetNoaSent, discloseCustomer, undiscloseCustomer, insureCustomer, uninsureCustomer, isRelatedPartyDebtor, isNotRelatedPartyDebtor, isGovernmentDebtor, isNotGovernmentDebtor, isMajorCorporationDebtor, isNotMajorCorporationDebtor, checkIcon }) => {
    const [customersArray, setCustomersArray] = useState([]);
    const [fundingSummary, setFundingSummary] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingSummary, setIsFetchingSummary] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortBy, setSortBy] = useState('selected_ledger');
    const [sortDirection, setSortDirection] = useState('');
    const [headerWidths, setHeaderWidths] = useState([]);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [isFeesModalVisible, setIsFeesModalVisible] = useState(false);
    const [promptCustomer, setPromptCustomer] = useState({});
    const [displaySearchInput, setDisplaySearchInput] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isSelectedWarningModalOpen, setIsSelectedWarningModalOpen] = useState(false);
    const closeApproveSelectedWarningModal = () => {
        setPromptCustomer({});
        setIsSelectedWarningModalOpen(false);
    };
    const closeApproveWarningModal = () => {
        setPromptCustomer({});
        setIsWarningModalOpen(false);
    };
    const [isApproveAllModalOpen, setIsApproveAllModalOpen] = useState(false);
    const closeApproveAllWarningModal = () => {
        setIsApproveAllModalOpen(false);
    };
    const headerRef = useRef(null);
    const errorMessage = 'Something went wrong. Please try again.';
    const [searchQuery, setSearchQuery] = useState('');
    const filterOptions = [
        {
            label: isManualLedger
                ? 'Selected Debtors with an outstanding balance'
                : 'All Debtors with an outstanding balance',
            value: isManualLedger ? 'selected_with_outstanding' : 'with_outstanding'
        },
        {
            label: 'All Selected Debtors',
            value: 'selected'
        },
        {
            label: 'All Unselected Debtors',
            value: 'unselected'
        }
    ];
    const [selectedFilterOptionValue, setSelectedFilterOptionValue] = useState(filterOptions[0].value);
    useEffect(() => {
        if (headerRef && headerRef.current) {
            // get current widths of headers to minimize jumping widths when table contents change
            const headerWidthList = [...headerRef.current.children[0].children].map(tableHeader => tableHeader.getBoundingClientRect().width);
            setHeaderWidths(headerWidthList);
        }
        fetchCustomers();
    }, [
        searchQuery,
        currentPage,
        sortBy,
        sortDirection,
        selectedFilterOptionValue
    ]);
    useEffect(() => {
        updateFundingSummary();
    }, [customersArray]);
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        const queryValue = event.currentTarget.value;
        debounceQueryUpdate(queryValue);
    };
    const debounceQueryUpdate = debounce((queryValue) => {
        // initiate search only on 3+ characters, or blank
        // Properly display search results regardless of current page
        if (queryValue.length >= 3 || queryValue === '') {
            setSearchQuery(queryValue);
            setCurrentPage(1);
        }
    }, 750);
    const handleFilterOptionChange = filterOption => {
        setSelectedFilterOptionValue(filterOption);
    };
    const fetchCustomers = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchCustomersForBorrower(apiToken, borrowerId, searchQuery ? 1 : currentPage, sortBy, sortDirection, searchQuery, selectedFilterOptionValue);
            const { page, pageSize, totalItems, totalPages } = data.attributes;
            setCustomersArray(page);
            setTotalPages(totalPages);
            setTotalItems(totalItems);
            setPageSize(pageSize);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsLoading(false);
        // Only display search input after initial fetch
        if (!displaySearchInput)
            setDisplaySearchInput(true);
    };
    const sortCustomers = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'desc');
        }
    };
    const handlePageClick = data => {
        // add 1 since pages from fetchCustomersForBorrower have 1 base index while react-pagination are 0 base
        setCurrentPage(data.selected + 1);
    };
    const updateCustomersArray = (updatedCustomer) => {
        const updatedCustomersArray = customersArray.map((customer) => {
            return updatedCustomer.id === customer.id
                ? {
                    ...customer,
                    earlypayStatus: updatedCustomer.earlypayStatus,
                    isSelected: updatedCustomer.isSelected,
                    creditChecked: updatedCustomer.creditChecked,
                    noaSent: updatedCustomer.noaSent,
                    isDisclosed: updatedCustomer.isDisclosed,
                    insuredAt: updatedCustomer.insuredAt
                }
                : customer;
        });
        setCustomersArray(updatedCustomersArray);
    };
    const updateFundingSummary = async () => {
        try {
            const { data } = await fetchFundingSummary(apiToken, borrowerId);
            setFundingSummary(data.attributes);
            setIsFetchingSummary(false);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const approveCustomerHandler = async (customerId, customer) => {
        setIsRequestPending(true);
        try {
            if (!customer.abn || !isValidAbn(customer.abn)) {
                setPromptCustomer(customer);
                setIsWarningModalOpen(true);
            }
            else if (!customer.isSelected) {
                setIsSelectedWarningModalOpen(true);
            }
            else {
                const { data } = await approveCustomer(apiToken, borrowerId, customerId);
                updateCustomersArray(data.attributes);
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const pendingCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await pendingCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unapproveAllCustomersHandler = async () => {
        try {
            await unapproveAllCustomers(apiToken, borrowerId, searchQuery);
            const updatedCustomersArray = customersArray.map((customer) => {
                customer.earlypayStatus = 'PENDING';
                return customer;
            });
            setCustomersArray(updatedCustomersArray);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const approveAllCustomersHandler = async () => {
        try {
            await approveAllCustomers(apiToken, borrowerId, searchQuery);
            const updatedCustomersArray = customersArray.map((customer) => {
                if (customer.abn)
                    customer.earlypayStatus = 'APPROVED';
                return customer;
            });
            setCustomersArray(updatedCustomersArray);
            setIsApproveAllModalOpen(false);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const deselectAllCustomersHandler = async () => {
        try {
            await deselectAllCustomers(apiToken, borrowerId, searchQuery);
            const updatedCustomersArray = customersArray.map((customer) => {
                customer.isSelected = false;
                return customer;
            });
            setCustomersArray(updatedCustomersArray);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const selectAllCustomersHandler = async () => {
        try {
            await selectAllCustomers(apiToken, borrowerId, searchQuery);
            const updatedCustomersArray = customersArray.map((customer) => {
                customer.isSelected = true;
                return customer;
            });
            setCustomersArray(updatedCustomersArray);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const selectCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await selectCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
        setIsFeesModalVisible(false);
    };
    const selectCustomerFeeHandler = async (customerId, adminFee, adminFeeReference, insuranceFee, insuranceFeeReference) => {
        setIsRequestPending(true);
        try {
            if ((adminFee.floatValue || insuranceFee.floatValue) === 0) {
                selectCustomerHandler(customerId);
            }
            else {
                const { data } = await selectCustomerFee(apiToken, borrowerId, customerId, adminFee, adminFeeReference, insuranceFee, insuranceFeeReference);
                updateCustomersArray(data.attributes);
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
        setIsFeesModalVisible(false);
    };
    const deselectCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await deselectCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const updateEarlypayStatusHandler = async (customerId, earlypayStatus) => {
        setIsRequestPending(true);
        try {
            const { data } = await updateEarlypayStatus(apiToken, borrowerId, customerId, earlypayStatus);
            updateCustomersArray(data.attributes);
            showNotifyToast({
                text: 'Approval status updated successfully.',
                type: 'success'
            });
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unsetCustomerCreditCheckHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await unsetCreditCheck(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const setCustomerCreditCheckHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await setCreditCheck(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const setCustomerNoaSentHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await setNoaSent(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unsetCustomerNoaSentHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await unsetNoaSent(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const discloseCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await discloseCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const undiscloseCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await undiscloseCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const insureCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await insureCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const uninsureCustomerHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await uninsureCustomer(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const promptCustomerFee = (customer) => {
        if (isCompanyActiveAndChargingFees) {
            isFeesModalVisible
                ? setIsFeesModalVisible(false)
                : setIsFeesModalVisible(true);
            setPromptCustomer(customer);
        }
        else {
            selectCustomerHandler(customer.id);
        }
    };
    const isRelatedPartyDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isRelatedPartyDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotRelatedPartyDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotRelatedPartyDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isGovernmentDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isGovernmentDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotGovernmentDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotGovernmentDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isMajorCorporationDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isMajorCorporationDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const isNotMajorCorporationDebtorHandler = async (customerId) => {
        setIsRequestPending(true);
        try {
            const { data } = await isNotMajorCorporationDebtor(apiToken, borrowerId, customerId);
            updateCustomersArray(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const { fundsAvailableAll, approvedReceivablesAll, unapprovedReceivablesAll, suspenseAdjustmentAll, concentrationAdjustmentAll, selectedLedger, approvedCustomersCount, unapprovedCustomersCount, pendingInvoices, ineligibleAdjustments, approvedLedger, isDebtorsLedgerRecalculating } = fundingSummary;
    // Display different funding summary figures for manual ledger and connected clients (Xero & MYOB)
    const fundingSummaryFigures = isManualLedger
        ? [
            { label: 'Selected Ledger', value: selectedLedger },
            {
                label: 'Number of Active Debtors',
                value: [
                    {
                        label: 'Approved',
                        value: approvedCustomersCount >= 0 ? `${approvedCustomersCount}` : ''
                    },
                    {
                        label: 'Unapproved',
                        value: unapprovedCustomersCount >= 0
                            ? `${unapprovedCustomersCount}`
                            : ''
                    }
                ]
            },
            { label: 'Unallocated Payments', value: suspenseAdjustmentAll },
            { label: 'Pending Invoices', value: pendingInvoices },
            {
                label: 'Ineligible Adjustments',
                value: isDebtorsLedgerRecalculating
                    ? 'Processing...'
                    : ineligibleAdjustments
            },
            {
                label: 'Approved Ledger (After Unallocated Payments)',
                value: isDebtorsLedgerRecalculating ? 'Processing...' : approvedLedger
            }
        ]
        : [
            { label: 'Adjusted Total Receivables', value: fundsAvailableAll },
            {
                label: 'Approved Receivables (After Concentration and Suspense)',
                value: approvedReceivablesAll
            },
            { label: 'Unapproved Receivables', value: unapprovedReceivablesAll },
            { label: 'Suspense Adjustment', value: suspenseAdjustmentAll },
            { label: 'Concentration Adjustment', value: concentrationAdjustmentAll }
        ];
    const generateExportedFilePathWithFilters = `${generateExportedFilePath}?filter=${selectedFilterOptionValue}`;
    return (React.createElement("div", { className: 'customers-dashboard customers -admin', "data-testid": 'admin-customers-dashboard' },
        React.createElement("div", { className: 'custom-panel summary' },
            React.createElement(FundingSummaryPanel, { figures: fundingSummaryFigures, isLoading: isFetchingSummary })),
        React.createElement("div", { className: 'table' },
            React.createElement(AdminCustomersTable, { ...{
                    approveAllCustomersHandler,
                    unapproveAllCustomersHandler,
                    apiToken,
                    borrowerCustomersPath,
                    borrowerId,
                    companyId,
                    isManualLedger,
                    isDebtorsLedgerRecalculating,
                    generateExportedFilePath: generateExportedFilePathWithFilters,
                    debtorFiles,
                    currentPage,
                    customersArray,
                    isLoading,
                    pageSize,
                    sortBy,
                    sortDirection,
                    totalPages,
                    totalItems,
                    enableActions,
                    invoiceFilter,
                    headerWidths,
                    approveCustomerHandler,
                    pendingCustomerHandler,
                    isRequestPending,
                    handlePageClick,
                    sortCustomers,
                    selectAllCustomersHandler,
                    deselectAllCustomersHandler,
                    selectCustomerHandler,
                    deselectCustomerHandler,
                    setCustomerCreditCheckHandler,
                    unsetCustomerCreditCheckHandler,
                    setCustomerNoaSentHandler,
                    unsetCustomerNoaSentHandler,
                    discloseCustomerHandler,
                    undiscloseCustomerHandler,
                    insureCustomerHandler,
                    uninsureCustomerHandler,
                    searchQuery,
                    handleSearchQueryChange,
                    promptCustomerFee,
                    isFeesModalVisible,
                    promptCustomer,
                    selectCustomerFeeHandler,
                    isRelatedPartyDebtorHandler,
                    isNotRelatedPartyDebtorHandler,
                    isGovernmentDebtorHandler,
                    isNotGovernmentDebtorHandler,
                    isMajorCorporationDebtorHandler,
                    isNotMajorCorporationDebtorHandler,
                    handleFilterOptionChange,
                    setIsWarningModalOpen,
                    setIsSelectedWarningModalOpen,
                    isWarningModalOpen,
                    isSelectedWarningModalOpen,
                    closeApproveWarningModal,
                    closeApproveSelectedWarningModal,
                    isApproveAllModalOpen,
                    closeApproveAllWarningModal,
                    setIsApproveAllModalOpen,
                    checkIcon,
                    displaySearchInput,
                    updateEarlypayStatusHandler,
                    filterOptions
                }, forwardHeaderRef: headerRef }))));
};
export default AdminCustomersDashboard;
