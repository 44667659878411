import classNames from 'classnames';
import React from 'react';
import PaginationBar from '../shared/PaginationBar';
// Components
import CustomersTableBody from './CustomersTableBody';
import SortableTableHeaders from '../table-headers/SortableTabs';
import ExportedFilesDownloadModal from '../shared/ExportFilesDownloadModal';
// import TriStateToggleSwitch from '../shared/TriStateToggleSwitch'
import TableSearchBar from '../shared/TableSearchBar';
import Filters from '../shared/Filters';
const CustomersTable = ({ currentPage, customersArray, isLoading, pageSize, sortBy, sortDirection, totalItems, totalPages, forwardHeaderRef, headerWidths, isManualLedger, debtorFiles, generateExportedFilePath, 
// deselectAllCustomersHandler,
handlePageClick, 
// selectAllCustomersHandler,
sortCustomers, switchOffHandler, switchOnHandler, handleSearchQueryChange, handleFilterOptionChange, setSelectedFilterOptionValue, selectedFilterOptionValue, setIsOpenedFilter, isOpenedFilter, filterOptions }) => {
    // const selectedCustomers = customersArray.filter(
    //   customer => customer.isSelected === true
    // ).length
    // const allCustomersSelected = selectedCustomers === customersArray.length
    // const someCustomersSelected =
    //   selectedCustomers !== customersArray.length && selectedCustomers !== 0
    // const noCustomersSelected = selectedCustomers === 0
    // const selectAllConfirmationText = `Are you sure? Selecting all customers will nominate all customers to be funded against. Should you wish to deselect any customers, please contact Earlypay.`
    const headerFields = isManualLedger
        ? [
            { text: 'Debtor Code', field: 'source_id', defaultSort: 'asc' },
            { text: 'Debtor Name', field: 'name', defaultSort: 'asc' },
            { text: 'Selected', field: 'is_selected' },
            { text: 'Approval Status', field: 'earlypay_status' },
            { text: 'Advance Rate', field: 'advance_rate' },
            { text: 'Insured Limit', field: 'approved_insurance_limit' },
            {
                text: 'Debtor Limit',
                field: 'debtor_limit'
            },
            {
                text: 'Concentration Limit'
            },
            {
                text: 'Selected Ledger',
                field: 'selected_ledger'
            },
            {
                text: 'Pending Invoices'
            },
            {
                text: 'Disapproved Invoices',
                field: 'disapproved_invoices'
            },
            {
                text: 'Debtor Limit Excess',
                field: 'debtor_limit_excess'
            },
            {
                text: 'Concentration Limit Excess'
            },
            {
                text: 'Ineligible Adjustments',
                field: 'ineligible_adjustments'
            },
            {
                text: 'Approved Ledger'
            },
            {
                text: 'Debtor Advance Rate Adjustment',
                field: 'advance_rate_adjustment'
            }
        ]
        : [
            { text: 'Customer', field: 'name', defaultSort: 'asc' },
            { text: 'Current' },
            { text: 'Overdue', field: 'invoice_amount_due', defaultSort: 'desc' },
            { text: 'Status', field: 'earlypay_status' },
            { text: 'Select', field: 'is_selected' },
            { text: 'Action' }
        ];
    const tableClassName = classNames('custom-table -overflow', {
        '-zoom-adjusted': isManualLedger
    });
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("div", { className: 'header -space-between' },
            React.createElement(TableSearchBar, { ...{ handleSearchQueryChange }, searchPlaceholder: 'Search debtor name or debtor code' }),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement(Filters, { ...{
                        handleFilterOptionChange,
                        setSelectedFilterOptionValue,
                        selectedFilterOptionValue,
                        filterOptions,
                        setIsOpenedFilter,
                        isOpenedFilter
                    } }),
                isManualLedger && (React.createElement(ExportedFilesDownloadModal, { debtorFiles: debtorFiles, generateExportedFilePath: generateExportedFilePath })))),
        React.createElement("table", { "data-testid": 'customers-table', className: tableClassName },
            React.createElement(SortableTableHeaders, { ...{
                    forwardHeaderRef,
                    headerFields,
                    headerWidths,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortCustomers }),
            React.createElement(CustomersTableBody, { ...{
                    customersArray,
                    isLoading,
                    switchOffHandler,
                    switchOnHandler,
                    isManualLedger
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize,
                totalPages,
                totalItems,
                handlePageClick
            }, resource: 'customers' }))));
};
export default CustomersTable;
