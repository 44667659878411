import React, { useState } from 'react';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { updateRecourseTerm } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { showNotifyToast } from '../../utils';
const RecourseTermForm = ({ authToken, companyId, recourseTerm, isReadOnly = false, isRecourseTermProcessing, setIsRecourseTermProcessing, incrementSaves }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [currentRecourseTerm, setCurrentRecourseTerm] = useState(recourseTerm);
    const handleRecourseTermChange = ({ target: { value } }) => {
        setCurrentRecourseTerm(value);
    };
    const handleSave = async () => {
        const body = {
            company: {
                recourseTerm: currentRecourseTerm
            }
        };
        setIsLoading(true);
        try {
            const response = await updateRecourseTerm(authToken, companyId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setCurrentRecourseTerm(response.data.attributes.recourseTerm);
            setIsRecourseTermProcessing(response.data.attributes.isRecourseTermProcessing);
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated recourse term setting.',
                type: 'success'
            });
            showNotifyToast({
                text: 'You may need to wait for a few minutes and refresh the page to see the changes.',
                type: 'info'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to updated recourse term setting.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form', "data-testid": 'amended-ageing-threshold-form' },
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Recourse Term"),
            React.createElement("input", { className: 'input form-control', type: 'number', defaultValue: currentRecourseTerm, onChange: handleRecourseTermChange }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'recourse_term' }),
            React.createElement("div", { className: 'text -spacer' }, "After updating the client's recourse term setting, only debtors that do not have a custom recourse term will be updated.")),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly && isRecourseTermProcessing, testId: 'save' }))));
};
export default RecourseTermForm;
