import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Modal from '../../components/shared/Modal';
import NumberFormat from 'react-number-format';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
const WriteOffForm = ({ csrfToken, formUrl, modelName, customers, isError, errors }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    const [writeOffAmount, setWriteOffAmount] = useState({
        floatValue: 0,
        value: '0',
        formattedValue: ''
    });
    const hasErrors = Object.keys(errors).length > 0 && isError;
    const hasDateError = errors.date !== undefined && isError;
    const [isVisible, setIsVisible] = useState(hasErrors);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const handleWriteOffChange = (values) => {
        setWriteOffAmount(values);
    };
    const writeOffLabelClass = classNames('label -required', {
        '-error': errors.writeOffAmount && isError
    });
    const writeOffInputClass = classNames('input form-control', {
        '-error': errors.writeOffAmount && isError
    });
    return (React.createElement("div", { className: 'action-buttons button' },
        React.createElement("div", { className: 'button -neutral', onClick: toggleModal }, "+ Add Create New Write Off"),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("form", { className: 'form', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
                React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'write-off-modal' },
                    React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                    React.createElement("div", { className: 'header' }, "Create Write Off"),
                    React.createElement("div", { className: 'content' },
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label' }, "Type"),
                            React.createElement("div", { className: 'data' }, "Write Off"),
                            React.createElement("input", { type: 'hidden', name: `${modelName}[provision_type]`, value: 'write_off' })),
                        React.createElement("div", { className: 'form-group', "data-testid": 'customer-select' },
                            React.createElement("label", { className: 'label' }, "Debtor"),
                            React.createElement(Select, { styles: {
                                    control: baseStyles => ({
                                        ...baseStyles
                                    })
                                }, placeholder: 'Select a Customer', name: `${modelName}[customer_id]`, options: customers })),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: writeOffLabelClass }, "Write Off Amount"),
                            React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: writeOffAmount.value, allowNegative: false, name: `${modelName}[write_off_amount]`, onValueChange: handleWriteOffChange, className: writeOffInputClass, "data-testid": 'write-off-amount' }),
                            isError && (React.createElement(ErrorList, { errors: errors, field: 'writeOffAmount' }))),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label' }, "Details"),
                            React.createElement("input", { className: 'input form-control', name: `${modelName}[notes]`, defaultValue: '' })),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: writeOffLabelClass }, "Date"),
                            React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, clearButtonText: 'Clear Date', hasError: hasDateError, name: `${modelName}[date]`, pickerBackground: 'neutral-20', placeholderText: ' ' }),
                            isError && React.createElement(ErrorList, { errors: errors, field: 'date' })),
                        React.createElement("div", { className: 'action-buttons' },
                            React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Cancel"),
                            React.createElement("button", { type: 'submit', className: 'button -primary' }, "Create"))))))));
};
export default WriteOffForm;
