import React, { useState } from 'react';
import classNames from 'classnames';
import Modal from '../../components/shared/Modal';
const EndorseButton = ({ path, endorser, disable }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const endorserClass = classNames('button', {
        '-neutral': !disable
    });
    const hasEndorse = endorser !== '';
    return (React.createElement("div", { className: 'action-buttons button', "data-testid": 'endorse-component' },
        !endorser && (React.createElement("button", { className: endorserClass, onClick: toggleModal, disabled: disable, "data-testid": 'endorse-button' }, "Endorse")),
        hasEndorse && endorser,
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("div", { className: 'form' },
                React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'endorse-button-modal' },
                    React.createElement("div", { className: 'header' }, "Confirmation Endorsement"),
                    React.createElement("div", { className: 'content' },
                        React.createElement("div", { className: 'section' },
                            React.createElement("div", { className: 'description' }, "Are you sure you want to endorse this provision?"))),
                    React.createElement("div", { className: 'content' },
                        React.createElement("div", { className: 'action-buttons' },
                            React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Cancel"),
                            React.createElement("a", { href: path, "data-method": 'put', className: 'button -primary' }, "Confirm"))))))));
};
export default EndorseButton;
