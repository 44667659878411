import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import Modal from '../shared/Modal';
import { convertCentsString, convertCents } from '../../utils/convertCents';
const initialFee = {
    formattedValue: '',
    value: convertCentsString(0),
    floatValue: convertCents(0)
};
const AdminCustomerFeeModal = ({ promptCustomer, isFeesModalVisible, promptCustomerFee, selectCustomerFeeHandler }) => {
    const [adminFee, setAdminFee] = useState(initialFee);
    const [adminFeeRefence, setAdminFeeReference] = useState();
    const [insuranceFee, setInsuranceFee] = useState(initialFee);
    const [insuranceFeeReference, setInsuranceFeeReference] = useState();
    const handleAdminFeeChange = (values) => {
        setAdminFee(values);
    };
    const handleInsuranceFeeChange = (values) => {
        setInsuranceFee(values);
    };
    return (React.createElement(Modal, { isOpen: isFeesModalVisible, onClose: () => promptCustomerFee(promptCustomer) },
        React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'customer-modal' },
            React.createElement("div", { className: 'header' }, "Selecting Customer"),
            React.createElement("div", { className: 'content' },
                React.createElement("div", { className: 'section' },
                    React.createElement("div", { className: 'description' },
                        React.createElement("span", { className: 'fees' },
                            "Add fees for ",
                            promptCustomer.name,
                            " up to and not including today")),
                    React.createElement("div", { className: 'description' },
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label -uppercase' }, "Admin Fee"),
                            React.createElement(NumberFormat, { value: adminFee.value, className: 'input form-control', prefix: '$', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleAdminFeeChange, id: 'customer-admin-fee', "data-testid": 'customer-admin-fee' }),
                            React.createElement("div", null,
                                React.createElement("span", null,
                                    "Suggested Admin Fee:",
                                    ' ',
                                    promptCustomer.suggestedAdminFeeFormatted),
                                React.createElement("div", null,
                                    "(",
                                    promptCustomer.adminFeeRateFormatted,
                                    " Admin Fee x",
                                    ' ',
                                    promptCustomer.currentReceivablesYesterdayFormatted,
                                    " total current outstanding)"),
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'label -uppercase' }, "Reference"),
                                    React.createElement("input", { type: 'text', className: 'input form-control', onChange: event => setAdminFeeReference(event.target.value) })))),
                        React.createElement("hr", { className: 'custom-divider' }),
                        React.createElement("div", { className: 'form-group' },
                            React.createElement("label", { className: 'label -uppercase' }, "Insurance Fee"),
                            React.createElement(NumberFormat, { value: insuranceFee.value, className: 'input form-control', prefix: '$', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleInsuranceFeeChange, id: 'customer-insurance-fee', "data-testid": 'customer-insurance-fee' }),
                            React.createElement("div", null,
                                React.createElement("span", null,
                                    "Suggested Insurance Fee:",
                                    promptCustomer.suggestedInsuranceFeeFormatted,
                                    ' '),
                                React.createElement("div", null,
                                    "(",
                                    promptCustomer.insuranceFeeRateFormatted,
                                    " Insurance Fee x",
                                    ' ',
                                    promptCustomer.currentReceivablesYesterdayFormatted,
                                    " total current outstanding)")),
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("label", { className: 'label -uppercase' }, "Reference"),
                                React.createElement("input", { type: 'text', className: 'input form-control', onChange: event => setInsuranceFeeReference(event.target.value) })))))),
            React.createElement("div", { className: 'action-buttons buttons space -padding' },
                React.createElement("button", { className: 'button -reset -transparent', onClick: () => promptCustomerFee(promptCustomer) }, "Cancel"),
                React.createElement("button", { className: 'button -primary', onClick: () => selectCustomerFeeHandler(promptCustomer.id, adminFee, adminFeeRefence, insuranceFee, insuranceFeeReference) }, "Submit")))));
};
export default AdminCustomerFeeModal;
