import React from 'react';
// Components
import ToggleSwitch from '../shared/ToggleSwitch';
import CustomerStatus from './CustomerStatus';
const CustomerRow = ({ customer, isManualLedger, switchOnHandler, switchOffHandler }) => {
    const tooltipText = 'If you no longer wish to fund against this customer, please leave a message and let Earlypay know.';
    const onConfirmationText = `Are you sure you want to select ${customer.name}?`;
    const offConfirmationText = `Are you sure you want to deselect ${customer.name}?`;
    if (isManualLedger) {
        return (React.createElement("tr", { "data-testid": 'customer-row-manual-ledger' },
            React.createElement("td", { className: 'cell' }, customer.sourceId),
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, customer.name)),
            React.createElement("td", { className: 'cell' }, customer.isSelected ? 'Yes' : 'No'),
            React.createElement("td", { className: 'cell' },
                React.createElement(CustomerStatus, { status: customer.earlypayStatus })),
            React.createElement("td", { className: 'cell' }, customer.advanceRateFormatted),
            React.createElement("td", { className: 'cell' }, customer.approvedInsuranceLimitFormatted === '-'
                ? '$0.00'
                : customer.approvedInsuranceLimitFormatted),
            React.createElement("td", { className: 'cell' },
                React.createElement("td", { className: 'cell' }, customer.debtorLimitFormatted)),
            React.createElement("td", { className: 'cell' }, customer.concentrationLimitFormatted),
            React.createElement("td", { className: 'cell' }, customer.selectedLedgerFormatted),
            React.createElement("td", { className: 'cell' }, customer.pendingInvoicesFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20 ' }, customer.disapprovedInvoicesFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20' }, customer.debtorLimitExcessFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20' }, customer.concentrationLimitExcessFormatted),
            React.createElement("td", { className: 'cell' }, customer.ineligibleAdjustmentsFormatted),
            React.createElement("td", { className: 'cell' }, customer.approvedLedgerFormatted),
            React.createElement("td", { className: 'cell' }, customer.advanceRateAdjustmentFormatted)));
    }
    else {
        return (React.createElement("tr", { "data-testid": 'customer-row' },
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, customer.name)),
            React.createElement("td", { className: 'cell' }, customer.currentReceivablesFormatted),
            React.createElement("td", { className: 'cell' }, customer.overdueReceivablesFormatted),
            React.createElement("td", { className: 'cell' },
                React.createElement(CustomerStatus, { status: customer.earlypayStatus })),
            React.createElement("td", { className: 'cell' },
                React.createElement(ToggleSwitch, { isToggled: customer.isSelected, isSwitchDisabled: customer.isSelected, tooltipText: tooltipText, switchOn: () => window.confirm(onConfirmationText) && switchOnHandler(customer.id), switchOff: () => window.confirm(offConfirmationText) &&
                        switchOffHandler(customer.id) })),
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, "View"))));
    }
};
export default CustomerRow;
